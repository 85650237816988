// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

// local imports
import { styles } from './faq.style';

function FAQ(props: WithStyles<typeof styles>) {
    const { classes } = props;

    const Question = ({ subtitle, body }: { subtitle: string; body: Array<string> }) => (
        <Box className={classes.section}>
            <p className={classes.subtitle}>{subtitle}</p>
            {body.map(item => (
                <p className={classes.text}>
                    {item} <br></br>
                </p>
            ))}
            <br></br>
            <br></br>
            <hr />
        </Box>
    );

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <Box className={classes.titleContainer}>
                    <p className={classes.title}>PREGUNTAS</p>
                    <p id="bot" className={classes.title}>
                        FRECUENTES
                    </p>
                </Box>
                <Question
                    subtitle="¿Qué es Cuenca?"
                    body={[
                        `Somos una Fintech que revoluciona las finanzas 
                        personales en México. Creamos un servicio 
                        financiero que nosotros usamos pensando en ti, 
                        desde tu teléfono móvil tienes acceso a tu dinero 
                        de forma segura e inmediata 24/7, puedes enviar y 
                        recibir fondos con múltiples opciones. Puedes abrir 
                        una cuenta en menos de 5 minutos, realizar compras 
                        en línea, pagar la renta, luz y todos tus gastos. 
                        ¡Cuenca es la única cuenta que necesitas! `,
                    ]}
                />
                <Question
                    subtitle="¿Quién puede abrir una cuenta Cuenca?"
                    body={[
                        `¡Cuenca es para ti, es para todxs! En dos 
                    sencillos pasos la puedes obtener: primero descarga 
                    la app, después regístrate.`,
                    ]}
                />
                <Question
                    subtitle="¿Qué necesito para abrir una cuenta?"
                    body={[
                        `Un teléfono inteligente e identificación oficial: 
                        INE, Pasaporte Mexicano, Tarjeta de Residencia o 
                        Matrícula Consular. Si necesitas una cuenta y no tienes 
                        identificación oficial pero tienes 18 años, solo 
                        necesitas tu CURP. Regístrate en menos de 5 minutos.`,
                    ]}
                />
                <Question
                    subtitle="¿Qué costo tiene abrir una cuenta?"
                    body={[`No tienen ningún costo abrir una cuenta con Cuenca.`]}
                />
                <Question
                    subtitle="¿Hay comisiones en Cuenca?"
                    body={[
                        `En Cuenca no cobramos comisiones por transferencias 
                        entre cuentas Cuenca o por Whastapp. Tienes 15 
                        transferencias gratis cada mes, a partir de la número 16, 
                        tienen un costo de $10 MXN cada una y el costo mensual 
                        por manejo de cuenta es de $50 MXN (IVA incluido). `,
                    ]}
                />
                <Question
                    subtitle="¿Por qué tengo dos tarjetas, la física y la virtual?"
                    body={[
                        `Tarjetas Cuenca es el socio comercial que emite 
                    las tarjetas, tanto virtual como física. La tarjeta virtual 
                    se asigna automáticamente cuando te registras con Cuenca y 
                    es más segura, pues sólo existe en tu aplicación. Al abrir 
                    tu cuenta automáticamente obtienes una tarjeta virtual con 
                    la cual puedes pagar todas tus compras en línea: desde tu súper 
                    hasta servicios streaming. ¡Hazlo de forma rápida, efectiva y 
                    sin efectivo!`,
                        `Si deseas obtener una tarjeta física, escoge 
                    tu diseño favorito, deposita $100.00 MXN (costo del envío, incluye 
                        IVA) y recíbela en casa.`,
                    ]}
                />
                <Question
                    subtitle="¿Qué son los Apartados de Cuenca?"
                    body={[
                        `Guarda tus fondos y logra tus metas con Apartados. 
                        El dinero en tus apartados está separado del saldo 
                        principal y no generará ningún rendimiento. Podrás 
                        tener acceso al dinero en tus apartados transfiriendolo 
                        de vuelta a tu saldo principal. No tiene un costo 
                        adicional, úsalo desde tu app. `,
                    ]}
                />
                <Question
                    subtitle="¿Se pueden realizar transferencias a otras cuentas 
                    que no son de Cuenca?"
                    body={[
                        `Se pueden realizar hasta 15 transferencias gratis a cuentas 
                        que no son Cuenca. Además se pueden realizar transferencias 
                        vía Whatsapp.`,
                    ]}
                />
                <Question
                    subtitle="¿Cuenca es una empresa mexicana?"
                    body={[
                        `Está constituida de acuerdo con la legislación mexicana, 
                        en específico con la Ley Fintech, sin embargo, tiene inversión 
                        extranjera.`,
                    ]}
                />
                <Question
                    subtitle="¿El área de soporte está disponible 24/7?"
                    body={[
                        `Cuenca tiene un área de soporte que se caracteriza 
                        por ser humana y empática. Luisa, nuestra agente de 
                        soporte, es muy amable y siempre busca las soluciones 
                        más eficaces y eficientes. Funciona a través de Whatsapp 
                        para proteger la información de los usuarios y la 
                        encriptación de los mensajes. Los horarios de atención 
                        son de lunes a viernes de 10:00 a 22:00 horas y sábados y 
                        domingos de 15:00 a 22:00 horas.`,
                    ]}
                />
                <Question
                    subtitle="¿Cuenca tiene sucursales?"
                    body={[
                        `La única sucursal que necesitas la tienes en la palma 
                        de tu mano en tu aplicación. No contamos con sucursales 
                        pero nuestro servicio está disponible las 24/7.`,
                    ]}
                />
                <Question
                    subtitle="¿Qué son personas de confianza?"
                    body={[
                        `Nuestro Servicio de Personas de Confianza es para reforzar 
                    la seguridad de tu cuenta, puedes solicitar a un amigo o familiar 
                    que te ayude a bloquear la tarjeta y recuperar tu contraseña. 
                    Ellos jamás podrán acceder a tu cuenta, únicamente apoyarte en el 
                    proceso. En caso de no querer agregar a nadie, ¡no te preocupes! 
                    El servicio es totalmente opcional, solamente da clic en la opción 
                    “Recordar más tarde” y listo, en el futuro podrás agregarlos.`,
                        `Recuerda que para que alguien participe en tu grupo de Personas de 
                    Confianza, debe crear o acceder a su cuenta Cuenca.`,
                    ]}
                />
                <Question
                    subtitle="¿Qué seguridad tienen mis tarjetas y cuenta?"
                    body={[
                        `Al ser una institución regulada, Cuenca cumple la regulación 
                    emitida por la Comisión Nacional Bancaria y de Valores (CNBV) y 
                    demás autoridades financieras, las cuales revisan constantemente 
                    que tus fondos y los de todxs nuestros clientes estén seguros. 
                    Adicionalmente, puedes activar el “modo seguridad” de tus tarjetas 
                    Cuenca en cualquier momento desde tu app.`,
                    ]}
                />
                <Question
                    subtitle="¿En dónde aceptan pagos con mi tarjeta Cuenca?"
                    body={[
                        `En todos los establecimientos en México y alrededor del mundo 
                        donde acepten Mastercard, además de la red de cajeros automáticos.`,
                    ]}
                />
                <Question
                    subtitle="¿Cómo se puede cancelar mi cuenta Cuenca?"
                    body={[
                        `Sentimos mucho escuchar que quieres cancelar tu cuenta :( Si deseas 
                        iniciar el proceso, te pedimos que nos contactes mediante el canal 
                        oficial de Whatsapp donde por motivos de seguridad y para verificar 
                        tu identidad el equipo de soporte te solicitará enviar un video selfie 
                        sosteniendo tu identificación/pasaporte mencionando tu nombre completo, 
                        la fecha del día y solicitud o puedes realizar la cancelación desde tu 
                        perfil en la app, seleccionado la opción “Cancelar cuenta”.`,
                        `Recuerda que debes haber cubierto las comisiones por manejo de cuenta 
                        hasta ese momento y tener tu saldo en $0.00.`,
                    ]}
                />
                <Question
                    subtitle="¿Puedo tener una cuenta en Cuenca sin pedir tarjetas?"
                    body={[
                        `Sí, para abrir una cuenta basta con registrarse sin 
                    solicitar necesariamente las tarjetas.`,
                    ]}
                />
                <Question
                    subtitle="¿Qué pasa con mi dinero si me roban o extravío mi 
                    tarjeta y/o teléfono inteligente?"
                    body={[
                        `Los fondos en tu cuenta siempre están protegidos siempre 
                    y cuando no haya acceso a la aplicación. Nuestro servicio de 
                    Personas de Confianza es para reforzar la seguridad de tu 
                    cuenta, puedes solicitar a un amigo o familiar que te ayude 
                    a bloquear la tarjeta y recuperar tu contraseña. Ellos jamás 
                    podrán acceder a tu cuenta, únicamente apoyarte en el proceso. 
                    Si no cuentas con el servicio de personas de confianza habilitado, 
                    puedes contactar a Luisa, de soporte, por Whatsapp desde cualquier 
                    número telefónico y tras una verificación de identidad podrán 
                    ayudarte con el bloqueo preventivo de tu cuenta y/o reposición 
                    de tus tarjetas.`,
                    ]}
                />
                <Question
                    subtitle="¿Puedo solicitar estados de cuenta?"
                    body={[
                        `Sí, para poder realizar la solicitud de un estado de cuenta 
                        es necesario cumplir con los siguientes requisitos: contar 
                        con un RFC dado de alta ante el SAT, constancia de situación 
                        fiscal y que coincidan con los datos registrados en tu app. 
                        Puedes generar los estados de cuenta directamente en tu app, 
                        en la sección “Estados de cuenta”`,
                    ]}
                />
                <Question
                    subtitle="¿Cuáles son los límites de mi cuenta?"
                    body={[
                        `Cada cuenta en Cuenca tiene 4 niveles posibles: 
                    En el Nivel 1 el saldo máximo en cuenta es de $6,500.00  
                    MXN y el límite de fondos entrantes al mes es de $5,000.00 MXN; 
                    en el Nivel 2 el límite de fondos entrantes al mes es de $20,000.00 MXN. 
                    El Nivel 3 tiene un límite en transferencias entrantes de 
                    $65,000.00 MXN al mes; en el Nivel 4 no tienes límites de 
                    fondos entrantes por transferencia, ni límite de saldo en cuenta.`,

                        `Para depósitos en efectivo estos son los siguientes límites:`,
                        `* Monto límite de depósitos de efectivo mensual: $68,500.00 MXN`,
                        `* Monto límite de depósitos en efectivo diario: $27,400.00 MXN
                    (el límite de depósitos en efectivo no puede ser modificado)`,

                        `Si deseas aumentar el límite puedes cargar un video de 
                    verificación de identidad desde tu app en la sección 
                    “Depósitos en efectivo“.`,
                    ]}
                />
                <Question
                    subtitle="¿Puedo usar mi tarjeta en el extranjero?"
                    body={[
                        `Las tarjetas Cuenca pueden utilizarse en todo el mundo, 
                        las 24 horas del día, los 7 días de la semana. No es 
                        necesario que nos notifiques previo a salir del país, 
                        tu tarjeta Cuenca está lista para viajar contigo.`,
                    ]}
                />
                <Question
                    subtitle="¿Puedo recibir transferencias desde cuentas en el extranjero?"
                    body={[
                        `Por el momento no contamos con IBAN/SWIFT para transferencias 
                        internacionales directas.`,
                    ]}
                />
                <Question
                    subtitle="¿Cómo activo mi tarjetas?"
                    body={[
                        `Para activar tu tarjeta física es necesario seguir los 
                    siguientes pasos: en tu app ve a la sección “tu tarjeta“ > 
                    “tarjeta física“ > “ya tengo mi tarjeta“ e ingresa los datos 
                    de tu plástico. La tarjeta virtual se activa automáticamente 
                    cuando abre tu cuenta Cuenca. `,
                    ]}
                />
                <Question
                    subtitle="¿Puedo cambiar mi NIP? / ¿Dónde puedo ver mi NIP?"
                    body={[
                        `Tu NIP es único e irremplazable. Lo encuentras en tu 
                    app *Tus tarjetas* > *Tu tarjeta física* > *Mi NIP*.`,
                    ]}
                />
                <Question
                    subtitle="¿Cómo puedo recibir transferencias desde otra institución?"
                    body={[
                        `Para recibir transferencias desde otra institución a tu cuenta 
                    Cuenca solo debes vincularla desde: “Cartera“ > Asociar cuenta 
                    bancaria > e ingresar tu CLABE interbancaria Cuenca que encuentras 
                    en la sección *“Recibir“* de tu app.`,
                    ]}
                />
                <Question
                    subtitle="¿Cómo puedo enviar una transferencia a otra institución?"
                    body={[
                        `Para transferir ingresa a tu app a Enviar > Selecciona el monto 
                    > Elige a tu contacto o ingresa la CLABE, ¡y listo!`,

                        `Recuerda que también puedes transferir vía Whatsapp, para realizar 
                    estas transferencias los números telefónicos de quien envía y quien 
                    recibe deben estar asociados a Whatsapp. Si el destinatario tiene una 
                    cuenta Cuenca, recibirá la transferencia de inmediato; en caso de no 
                    tener Cuenca, le enviaremos un link donde deberá ingresar los datos 
                    de su institución financiera para recibir los fondos.`,
                    ]}
                />
                <Question
                    subtitle="¿Cómo puedo pagar servicios desde mi app? /¿Qué servicios 
                    puedo pagar?"
                    body={[
                        `Consulta los más de 18 mil establecimientos disponibles en 
                    tu app “Hacer Pagos”. Se pueden cubrir servicios de electricidad, 
                    agua, televisión por cable, tarjetas de crédito, gas, y plan móvil, 
                    entre otros.`,
                    ]}
                />
                <Question
                    subtitle="¿Puedo abrir una cuenta empresarial?"
                    body={[
                        `Por el momento no contamos con cuentas para personas morales, 
                    estamos dedicados en ofrecer los mejores productos para personas 
                    físicas únicamente.`,
                    ]}
                />
                <Question
                    subtitle="¿Qué tratamiento se le da a mis datos personales? / 
                    ¿Quién tiene acceso a mis datos personales? / ¿Cómo están 
                    protegidos mis datos personales?"
                    body={[
                        `Cuenca es la responsable del tratamiento de los datos 
                    personales que se recolectan a través de su plataforma, 
                    página de internet y medios electrónicos de contacto y/o 
                    soporte al cliente.`,

                        `Los datos personales que se recaban por Cuenca serán 
                    utilizados con la finalidad de proporcionar, mantener y 
                    mejorar nuestros servicios financieros; y podrá realizar 
                    transferencias de datos únicamente con las empresas integrantes 
                    de su mismo grupo empresarial, con los terceros y autoridades 
                    referidos en el Aviso de Privacidad únicamente a fin de dar 
                    cumplimiento a la regulación aplicable a las Instituciones de 
                    Fondos de Pago Electrónico.`,

                        `Cuenca no venderá, cederá o transferirá los datos personales 
                    del cliente o usuario a terceros ajenos a la empresa, 
                    sus filiales, subsidiarias y partes relacionadas, o terceros 
                    distintos a los anteriormente mencionados sin su consentimiento 
                    previo.`,

                        `Si desea conocer nuestro Aviso de Privacidad integral, lo 
                    podrá consultar en la siguiente dirección electrónica o 
                    directamente en nuestra aplicación móvil: 
                    https://cuenca.com/aviso-privacidad. `,
                    ]}
                />
                <Question
                    subtitle="¿Cómo puedo disponer de los fondos de mi cuenta?"
                    body={[`En tu aplicación o a través de tus Tarjetas Cuenca.`]}
                />
            </Grid>
        </Grid>
    );
}

export default withStyles(styles)(FAQ);
